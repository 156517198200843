import RequestContainer from 'components/containers/request.container';
import SubpageContainer from 'components/containers/subpage.container';
import FormCheckboxSingle from 'components/forms/form-checkbox-single';
import FormCustom from 'components/forms/form-custom';
import FormElement from 'components/forms/form-element';

import FormInput from 'components/forms/form-input';
import FormSubmit from 'components/forms/form-submit';
import { UserRolesType } from 'components/sidebar/navigation.interface';
import { getAdminRolesList } from 'components/sidebar/sidebar-user-navigation';
import useFetch from 'hooks/useFetch';
import { AddUser, getUserDetails, UpdateUser } from 'pages/admin/manage-users/manage-users.thunks';
import RebatesShimmer from 'pages/users/funnels/shimmers/rebates';
import { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';
import { selectRequest } from 'request/request.slice';

function ManageUsersForm() {
  const navigate = useNavigate();
  const { id } = useParams();
  const isCreating = id === 'new';
  let user = {
    roles: [],
    isVerified: true,
  };
  let isFetching = false;

  if (!isCreating) {
    ({ isFetching, data: user } = useFetch('manage-user', () => getUserDetails(id)));
  }

  // initialize user before fetching is over
  user = !Array.isArray(user) ? user : { roles: [], isVerified: true };

  const onSubmit = async (values) => {
    if (isCreating) {
      const res = await AddUser(values);
      if (res) {
        setTimeout(() => navigate('/dashboard/users'), 2000);
      }
    } else {
      await UpdateUser(id, values);
    }
  };

  // we don't need to show isForAll items
  const roles: UserRolesType[] = getAdminRolesList();

  return (
    <SubpageContainer title={isCreating ? 'Add New User' : 'Update User'}>
      <RequestContainer loading={isFetching} fallback={<RebatesShimmer />}>
        <Form
          onSubmit={onSubmit}
          initialValues={user}
          render={({ handleSubmit, values }) => {
            const [changePassword, setChangePassword] = useState(false);
            const { isLoading } = useAppSelector(selectRequest);
            return (
              <form onSubmit={handleSubmit}>
                <div className="space-y-6 bg-white rounded-md">
                  <div className="grid sm:w-full xl:w-3/4 grid-cols-1 p-6 gap-4">
                    <div className=" grid grid-cols-1 gap-4">
                      <FormInput name="firstName" label="First Name" />
                      <FormInput name="lastName" label="Last Name" />
                      <FormInput name="email" label="Email" />
                      <FormCustom label="Roles">
                        <div className="grid grid-cols-1 gap-2">
                          <div className="flex pr-6">
                            <label className="inline-flex items-center">
                              <Field
                                name="roles"
                                type="checkbox"
                                component="input"
                                format={(v) => v[0] === '*'}
                                parse={(v) => (v ? ['*'] : [])}
                                className="rounded-sm text-red-500 focus:ring-red-500"
                              />
                              <span className="ml-2 sm:text-xs lg:text-sm font-medium text-gray-700">All</span>
                            </label>
                          </div>
                          <div className="sm:grid grid-cols-4 lg:grid-cols-4 gap-2">
                            {roles.map((role) => {
                              return (
                                <label className="inline-flex items-center" key={role.id}>
                                  <Field
                                    name="roles"
                                    type="checkbox"
                                    component="input"
                                    value={role.id}
                                    checked={
                                      values?.roles[0] === '*' || values?.roles?.includes(role?.id) ? true : false
                                    }
                                    disabled={values?.roles[0] === '*'}
                                    className="rounded-sm text-red-500 focus:ring-red-500 disabled:opacity-50"
                                  />
                                  <span className="ml-2 sm:text-xs lg:text-sm  font-medium text-gray-700">
                                    {role.name}
                                  </span>
                                </label>
                              );
                            })}
                          </div>
                        </div>
                      </FormCustom>
                      {isCreating || changePassword ? (
                        <FormInput name="password" required label="Password" type="password" />
                      ) : (
                        <FormElement label="Password">
                          <button
                            type="button"
                            onClick={() => setChangePassword(true)}
                            className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                          >
                            Change Password
                          </button>
                        </FormElement>
                      )}

                      <FormElement label="Verify">
                        <FormCheckboxSingle name="isVerified" label="" />
                      </FormElement>
                    </div>
                    <div className="grid grid-cols-1 sm:items-start sm:border-t bg-gray-100 rounded-md sm:border-gray-200 sm:py-5">
                      <div className="grid grid-cols-4 gap-4">
                        <div />
                        <div className="mt-1 space-x-5 col-span-3">
                          <FormSubmit label="Submit" showButtonOnly disabled={isLoading} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </RequestContainer>
    </SubpageContainer>
  );
}

export default ManageUsersForm;
