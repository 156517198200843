import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { deleteDialogueReducer } from 'components/dialogs-old/confirm-dialog.slice';
import { sidebarReducer } from 'components/sidebar/sidebar.slice';
import { sliderOverReducer } from 'components/slide-over/slider-over.slice';
import { superLinksReducer } from 'components/super-links/super-links.slice';
import { usersReducer } from 'pages/admin/manage-users/manage-users.slice';
import { searchUserReducer } from 'pages/admin/manage-users/switch-user/switch-user.slice';
import { amazonAdsReducer } from 'pages/users/amazon/amazon-ads.slice';
import { amazonReducer } from 'pages/users/amazon/amazon.slice';
import { reportsByDateReducer } from 'pages/users/analytics/analytics-ads.slice';
import { salesByDateReducer } from 'pages/users/analytics/analytics-sales.slice';
import { funnelCustomerReducer } from 'pages/users/funnels/funnel-customers/funnel-customers.slice';
import { payoutModalReducer } from 'pages/users/funnels/funnel-customers/payout/payout-modal.slice';
import { funnelsReducer } from 'pages/users/funnels/funnels.slice';
import { funnelReducer } from 'pages/users/funnels/rebate/rebate.slice';
import { rebateSiteReducer } from 'pages/users/funnels/rebate/tabs/rebate-funnel-site-slice';
import { selectProductReducer } from 'pages/users/funnels/select-product/select-product.slice';
import { groupReducer } from 'pages/users/heatmap/groups/heatmap-groups.slice';
import { heatMapItemReducer } from 'pages/users/heatmap/heat-map-item.slice';
import { heatMapListReducer } from 'pages/users/heatmap/heat-map-list.slice';
import { productsReducer } from 'pages/users/products/products.slice';
import { redirectLinksReducer } from 'pages/users/magic-links/magic-link.slice';
import { defaultCardSliceReducer } from 'pages/users/subscription/payment-method/default-card.slice';
import { userReducer } from 'pages/users/user/user.slice';
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { requestReducer } from 'request/request.slice';
import { HeatmapSliceReducer } from '../pages/users/heatmap/heatmap.slice';

const reducer = {
  user: userReducer,
  request: requestReducer,
  sidebar: sidebarReducer,
  links: redirectLinksReducer,
  funnels: funnelsReducer,
  dialog: deleteDialogueReducer,
  selectProduct: selectProductReducer,
  funnel: funnelReducer,
  customers: funnelCustomerReducer,
  payoutModal: payoutModalReducer,
  heatMapList: heatMapListReducer,
  group: groupReducer,
  heatmap: HeatmapSliceReducer,
  users: usersReducer,
  searchUser: searchUserReducer,
  rebateSite: rebateSiteReducer,
  amazon: amazonReducer,
  amazonAds: amazonAdsReducer,
  products: productsReducer,
  superLinks: superLinksReducer,
  heatMapItem: heatMapItemReducer,
  reportsByDate: reportsByDateReducer,
  salesByDate: salesByDateReducer,
  sliderOver: sliderOverReducer,
  defaultCard: defaultCardSliceReducer,
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: ['requestReducers'],
};

export const persistedReducer = persistReducer(persistConfig, combineReducers(reducer));

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
