import { Tabs } from '@mantine/core';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/outline';

export default function TableTabs({ children }) {
  const navigate = useNavigate();
  const { status } = useParams();
  return (
    <>
      <div>
        <Tabs
          value={status ? status : 'active'}
          onTabChange={(value) => {
            navigate(`/dashboard/users/${value}`);
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="active" icon={<CheckCircleIcon className="h-4 w-4 text-green-600" />}>
              Active
            </Tabs.Tab>
            <Tabs.Tab value="inactive" icon={<XCircleIcon className="h-4 w-4 text-red-600" />}>
              Inactive
            </Tabs.Tab>
          </Tabs.List>
          <div className="pt-5">{children}</div>
        </Tabs>
      </div>
    </>
  );
}
