import { LightBulbIcon, UsersIcon } from '@heroicons/react/outline';
import { SidebarGroupedNavigationInterface } from './navigation.interface';

const adminNavigation = (): SidebarGroupedNavigationInterface[] => {
  return [
    {
      name: 'Users',
      subMenu: [{ name: 'Manage Users', href: 'users', icon: UsersIcon, current: false, id: 'users' }],
    },
    {
      name: 'Prompts',
      subMenu: [{ name: 'Apollo Prompts', href: 'prompts', icon: LightBulbIcon, current: false, id: 'prompts' }],
    },
  ];
};

export default adminNavigation;
