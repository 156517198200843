import { TableColumnRowInterface, tableColumns } from 'components/tables/table-body.helper';
import { UsersInterface } from 'interfaces/users.interace';
import RoleBadge from 'pages/admin/manage-users/manage-users-role.badge';
import SwitchButton from 'pages/admin/manage-users/manage-users-switch-button';
import React from 'react';
import { ActionIcon, Tooltip } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { CogIcon } from '@heroicons/react/solid';
import { friendlyDate } from 'lib/formatting.helpers';

function ManageUsersTableData(): TableColumnRowInterface[] {
  const navigate = useNavigate();
  const columnData = [
    {
      key: 'email',
      render: (rowData: UsersInterface) => {
        const id = rowData?._id;
        return (
          <a href={`/dashboard/users/edit/${id}`} className="hover:underline">
            {rowData.email}
          </a>
        );
      },
    },
    {
      key: '_id',
      render: (rowData: UsersInterface) => {
        const id = rowData?._id;
        return (
          <Tooltip label="Manage Account" offset={8}>
            <ActionIcon
              variant="subtle"
              radius="xl"
              color="dark"
              className="hover:text-white hover:!bg-slate-900"
              onClick={() => navigate(`/dashboard/users/manage/${id}`)}
            >
              <CogIcon className={`h-5 w-5 p-0 m-0 flex justify-center `} />
            </ActionIcon>
          </Tooltip>
        );
      },
    },
    {
      key: 'users',
      render: (rowData: UsersInterface) => {
        return <SwitchButton userId={rowData?._id} label={rowData?.email} />;
      },
    },
    {
      key: 'firstName',
      render: (rowData: UsersInterface) => {
        const name = rowData?.firstName ? `${rowData.firstName}` : '—';
        return <span>{name}</span>;
      },
    },
    {
      key: 'lastName',
      render: (rowData: UsersInterface) => {
        const name = rowData?.lastName ? `${rowData.lastName}` : '—';
        return <span>{name}</span>;
      },
    },
    {
      key: 'roles',
      render: (rowData: UsersInterface) => <RoleBadge roles={rowData.roles} />,
    },
    {
      key: 'createdAt',
      render: (rowData: UsersInterface) => (
        <span className="whitespace-nowrap	">{friendlyDate(new Date(rowData.createdAt))}</span>
      ),
    },
  ];

  return tableColumns(columnData, { url: '/dashboard/users', field: 'email' });
}

export default ManageUsersTableData;
